import { Button, Divider, InputGroup, Intent } from "@blueprintjs/core";
import { RootState } from "contorller";
import { AuthState } from "contorller/auth/reducer";
import React from "react";
import { useSelector } from "react-redux";
import "./index.scss";

interface LoginProps {
    onLogin: (email: string, password: string) => Promise<void>;
}
export const LoginForm = (props: LoginProps): JSX.Element => { 


    const auth = useSelector<RootState, AuthState>(state => state.auth)
    const emailRef = React.createRef<HTMLInputElement>();
    const passwordRef = React.createRef<HTMLInputElement>();

    const login = (): void => {
        if (emailRef.current !== undefined && passwordRef.current !== undefined) {
            if (emailRef.current !== null && passwordRef.current !== null) {
                props.onLogin(emailRef.current.value, passwordRef.current.value)
            }
        }
    }


    return <React.Fragment>
        <form className="loginForm" name="loginForm" onSubmit={() => console.log('Submit form')}>
            <InputGroup autoComplete="email" leftIcon="envelope" inputRef={emailRef} placeholder="email" type="email" />
            <InputGroup autoComplete="current-password" leftIcon="lock" inputRef={passwordRef} placeholder="password" type="password" />
            <Divider />
            <Button intent={Intent.PRIMARY} type="button" fill loading={auth.isFething} onClick={login}>Accedi</Button>
        </form>
    </React.Fragment>
}
