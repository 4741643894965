import { Button, Card, InputGroup } from "@blueprintjs/core";
import { ErrorBox } from "components/atoms/error";
import { Row } from "components/atoms/layout";
import { JsonResponseError } from "contorller";
import { adminGroupService, Group } from "contorller/admin/groups/controller";
import { adminActions } from "contorller/admin/redux";
import React, { createRef, useState } from "react";
import { useDispatch } from "react-redux";

export const GroupComponent = (props: { group: Group }) => {
  const dispatch = useDispatch();

  const ref = createRef<HTMLInputElement>();
  const [error, setError] = useState<JsonResponseError | undefined>();
  return (
    <Card className="group">
      <h3>{props.group.name}</h3>
      <br />

      {props.group.codes.map((e) => {
        return (
          <div className="code">
            <b>{e}</b>{" "}
            <Button
              onClick={() => {
                adminGroupService()
                  .removeUserCode(props.group.id, ref.current!.value)
                  .then((group) => {
                    dispatch(adminActions.replaceGroup(group));
                  })
                  .catch((e) => setError(e));
              }}
              intent="danger"
              icon="cross"
              minimal
            />
          </div>
        );
      })}
      <br />
      <Row>
        <InputGroup inputRef={ref} placeholder="Aggiungi codice" />
        <Button
          minimal
          intent="primary"
          icon="add"
          onClick={() => {
            if (ref.current) {
              adminGroupService()
                .addUserCode(props.group.id, ref.current!.value)
                .then((group) => {
                  dispatch(adminActions.replaceGroup(group));
                })
                .catch((e) => setError(e));
            }
          }}
        />
      </Row>
      <ErrorBox error={error} />
    </Card>
  );
};
