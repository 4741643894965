import { Button, Menu, MenuDivider, MenuItem, Navbar } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Logo } from "components/atoms/logo";
import { SeeLogged } from "components/atoms/seeLogged";
import { getStatusStage } from "config";
import { confortableController } from "contorller/confrontable/controller";
import { picController } from "contorller/simulations/PIC/controller";
import React from "react";
import { useHistory } from "react-router-dom";
import { ProfileMenu } from "../login-menu-user";
import BancoNavBar from "./bancoNavbar";

interface Actions {
  onAnalysis: () => void;
  onPortfolio: () => void;
  onVideoTutorial: () => void;
  onContacts: () => void;
  onExitEmpirich: () => void;
}

export const NavEmpirich = () => {
  const story = useHistory();

  const actions: Actions = {
    onAnalysis: () => {
      confortableController().clearAll();
      picController().clearAllResults();
      window.location.href = "/portfolio";
      //story.p,ush("/portfolio");
    },
    onPortfolio: () => {
      window.location.href = "/portfolios";
    },
    onVideoTutorial: () => {},
    onContacts: () => {
      story.push("/contacts");
    },
    onExitEmpirich: () => {
      window.location.href = "https://empirich.com";
    },
  };

  if (getStatusStage() === "mes") {
    return <BancoNavBar />;
  }

  return (
    <Navbar>
      <Navbar.Group>
        <Navbar.Heading>
          <Logo
            style={{
              backgroundColor: "#0E0B20",
            }}
            onClick={() => story.push("/")}
            color="light"
            size="medium"
          />
        </Navbar.Heading>

        <div className="hidden md:block">
          <SeeLogged>
            <Button
              minimal
              className="button-nav"
              icon="chart"
              text="Analisi"
              onClick={actions.onAnalysis}
            />
            <Button
              minimal
              icon="folder-open"
              className="button-nav"
              text="Portafogli"
              onClick={actions.onPortfolio}
            />
          </SeeLogged>
          <Button
            minimal
            className="button-nav"
            text="Video Tutorial"
            icon="video"
          />
          <Button
            minimal
            className="button-nav"
            icon="comment"
            text="Contatti"
            onClick={actions.onContacts}
          />
          <Button
            minimal
            className="button-nav"
            icon="log-out"
            onClick={actions.onExitEmpirich}
            text="Ritorna su Empirich 1"
          />
        </div>
        <div className="md:hidden">
          <Popover2 content={<NavBarMenu actions={actions} />}>
            <Button icon="menu" text="Menu" className="button-nav" minimal />
          </Popover2>
        </div>
      </Navbar.Group>

      <Navbar.Group align="right">
        <ProfileMenu />
      </Navbar.Group>
    </Navbar>
  );
};

function NavBarMenu(p: { actions: Actions }) {
  return (
    <Menu>
      <MenuItem text="Analisi" icon="chart" onClick={p.actions.onAnalysis} />
      <MenuItem
        text="Portafogli"
        icon="folder-open"
        onClick={p.actions.onPortfolio}
      />
      <MenuItem text="Video Tutorial" icon="video" />
      <MenuItem text="Contatti" icon="comment" />
      <MenuDivider />
      <MenuItem
        text="Ritorna su Empirich 1"
        icon="log-out"
        onClick={p.actions.onExitEmpirich}
      />
    </Menu>
  );
}
