import React from "react";

interface FlexProps {
  className?: string;
  crossAxisAlignment?:
    | "baseline"
    | "strech"
    | "start"
    | "end"
    | "flex-start"
    | "flex-end"
    | "center";
  mainAxisAlignment?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "strech"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "start"
    | "end"
    | "left"
    | "right"
    | "safe"
    | "unsafe";
  mainAxisSize?: "min" | "max";
  children?: React.ReactNode;
}

export const Column = (props: FlexProps): JSX.Element => {
  return (
    <div
      className={props.className}
      style={{
        display: "flex",
        justifyContent: props.mainAxisAlignment,
        alignItems: props.crossAxisAlignment,
        flexDirection: "column",
        height: props.mainAxisSize === "min" ? "auto" : "auto",
      }}
    >
      {props.children}
    </div>
  );
};

export const Row = (props: FlexProps): JSX.Element => {
  return (
    <div
      className={props.className}
      style={{
        display: "flex",
        alignItems: props.crossAxisAlignment,
        justifyContent: props.mainAxisAlignment,
        flexDirection: "row",
        width: props.mainAxisSize === "min" ? "auto" : "100%",
      }}
    >
      {props.children}
    </div>
  );
};

export interface ExpandedProps {
  children?: React.ReactNode;
  flex?: number;
}

export const Expanded = (props: ExpandedProps): JSX.Element => {
  return (
    <div
      style={{
        flexGrow: props.flex || 1,
      }}
      className="spinanico-expanded"
    >
      {props.children}
    </div>
  );
};

export interface CenterProps {
  children?: React.ReactNode;
}

export const Center = (props: CenterProps): JSX.Element => {
  return React.createElement("center", null, props.children);
};

export interface PageProps {
  children?: React.ReactNode;
  maxWidth?: number;
  minWidth?: number;
}

export const Page = (props: PageProps): JSX.Element => {
  return (
    <div className="page">
      <Center>
        <div
          style={{
            maxWidth: `${props.maxWidth || 800}px`,
            minWidth: `${props.minWidth || 200}px`,
          }}
          className="page-internal"
        >
          {props.children}
        </div>
      </Center>
    </div>
  );
};
