import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonResponseError, Sum } from "contorller";
import { maxNumber, minNumber } from "contorller/utils";
import { Portfolio, PortfolioFund, PortfolioTypes } from "./types";
import { percentageFundRebalance } from "./utils";

export interface PortfolioState {
  portfolios: Portfolio[];
  sectionLoaded: boolean;
  error?: JsonResponseError;
  loading: boolean;
  // portfolio?: Portfolio;

  view: {
    portfolio?: Portfolio;
    error?: JsonResponseError;
  };

  create: {
    portfolio: Portfolio;
    error?: JsonResponseError;
    lastidCreated: string;
  };

  cpError?: JsonResponseError;
  showError?: JsonResponseError;
}

const placeholderNewPortfolio: Portfolio = {
  name: "Nuovo Portfolio",
  funds: [],
  type: "percentage",
  description: "",
  uid: "",
  id: "",
  viewers: [],
  history: { start: 0, end: 0 },
};
export const initPortfolioState = () => {
  return { ..._init };
};
const _init: PortfolioState = {
  loading: true,
  portfolios: [],
  sectionLoaded: false,
  create: {
    portfolio: placeholderNewPortfolio,
    lastidCreated: "",
  },
  view: {},
};

type actionWithError = { error: JsonResponseError };

const portfolioStore = createSlice({
  name: "portfolio",
  reducers: {
    sectionLoaded: (state) => {
      state.sectionLoaded = true;
    },

    error: (state, action: PayloadAction<actionWithError>) => {
      state.error = action.payload.error;
    },
    share_error: (state, action: PayloadAction<actionWithError>) => {
      state.showError = action.payload.error;
    },
    cp_error: (state, action: PayloadAction<actionWithError>) => {
      state.cpError = action.payload.error;
    },

    findAndReplacePortfolio: (
      state,
      action: PayloadAction<{ portfolio: Portfolio }>
    ) => {
      const position = state.portfolios
        .map((e) => e.id)
        .indexOf(action.payload.portfolio.id);
      if (position !== -1) {
        state.portfolios[position] = action.payload.portfolio;
      }
    },

    prependPortfolio: (
      state,
      action: PayloadAction<{ portfolio: Portfolio }>
    ) => {
      if (
        state.portfolios.filter((e) => e.id === action.payload.portfolio.id)
          .length === 0
      ) {
        const portfolios = [action.payload.portfolio];
        state.portfolios = portfolios.concat(...state.portfolios);
      }
    },
    resetPortfolios: (state) => {
      state.portfolios = [];
    },
    appendPortfolios: (
      state,
      action: PayloadAction<{ portfolios: Portfolio[] }>
    ) => {
      for (let p of action.payload.portfolios) {
        if (state.portfolios.filter((e) => e.id === p.id).length === 0) {
          state.portfolios.push(p);
        }
      }
    },

    changeFundsInPortfolioLoaded: (
      state,
      action: PayloadAction<{ funds: PortfolioFund[] }>
    ) => {
      if (state.view.portfolio) {
        state.view.portfolio.funds = [];
        state.view.portfolio.funds.push(...(action.payload.funds || []));
        state.view.portfolio.history = {
          end: maxNumber(
            state.view.portfolio.funds.map<number>((e) => e.fund.history.end)
          ),
          start: minNumber(
            state.view.portfolio.funds.map<number>((e) =>
              e.proxed && e.fund.history.proxy !== undefined
                ? e.fund.history.proxy
                : e.fund.history.start
            )
          ),
        };
      } else {
        throw new Error("Devi caricare eun portfolio prima");
      }
    },

    appendFundInPortfolioLoaded: (
      state,
      action: PayloadAction<{
        funds: PortfolioFund[];
      }>
    ) => {
      const sum = Sum(action.payload.funds.map<number>((e) => e.money));
      const hasAmount = sum > 0;
      console.log(sum, hasAmount, action.payload.funds);
      if (state.view.portfolio) {
        (action.payload.funds || [])
          //.filter((f) => f.money > 0)
          .forEach((e) => {
            // se money è diverso da 0 significa che è stato importato da mediolanum
            // di conseguenze si settano le percentuale in base alla moneta
            if (e.money !== 0 && sum !== 0) {
              e.percentage = e.money / sum;
            }
            if (state.view.portfolio)
              if (
                state.view.portfolio.funds.map((e) => e.id).indexOf(e.id) === -1
              ) {
                state.view.portfolio.funds.push(e);
              }
          });
        state.view.portfolio.type = hasAmount ? "money" : "percentage";
        state.view.portfolio.history = {
          start: state.view.portfolio.funds
            .map<number>((e) =>
              e.proxed && e.fund.history.proxy !== undefined
                ? e.fund.history.proxy
                : e.fund.history.start
            )
            .sort((p, q) => q - p)[0],
          end: state.view.portfolio.funds
            .map<number>((e) => e.fund.history.end)
            .sort((p, q) => p - q)[0],
        };
      } else {
        throw new Error("Devi caricare eun portfolio prima");
      }
    },

    portfolioSelect: (
      state,
      action: PayloadAction<{ portfolio: Portfolio }>
    ) => {
      if (!action.payload.portfolio)
        throw new Error("Errore non trovo il portfolio nella action");

      state.view.portfolio = action.payload.portfolio;
      state.view.portfolio.funds.forEach(
        (f) => (f.proxed = f.fund.history.proxy !== undefined ? true : false)
      );
      state.view.portfolio.history = {
        start: state.view.portfolio.funds
          .map<number>((e) =>
            e.proxed ? e.fund.history.proxy : e.fund.history.start
          )
          .sort((p, q) => q - p)[0],
        end: state.view.portfolio.funds
          .map<number>((e) => e.fund.history.end)
          .sort((p, q) => p - q)[0],
      };
    },
    newPortfolio: (state) => {
      state.view.portfolio = placeholderNewPortfolio;
    },
    changeTypeInLoadedPortfolio: (
      state,
      action: PayloadAction<{ type: PortfolioTypes }>
    ) => {
      if (state.view.portfolio) {
        state.view.portfolio.type = action.payload.type;
      }
    },
    portfolioMakerChange: (
      state,
      action: PayloadAction<{ portfolio: Portfolio }>
    ) => {
      const sum = Sum(
        action.payload.portfolio.funds.map<number>((e) => e.money)
      );

      if (
        action.payload.portfolio.funds.map((e) => e.id).join("") !==
        state.create.portfolio.funds.map((e) => e.id).join("")
      )
        action.payload.portfolio.funds.forEach((e) => {
          // se money è diverso da 0 significa che è stato importato da mediolanum
          // di conseguenze si settano le percentuale in base alla moneta
          if (e.money !== 0 && sum !== 0) {
            e.percentage = parseFloat((e.money / sum).toFixed(2));
          }
        });
      state.create.portfolio = action.payload.portfolio;
    },
    setPortfolios: (
      state,
      action: PayloadAction<{ portfolios: Portfolio[] }>
    ) => {
      state.portfolios = action.payload.portfolios.filter((p) => {
        let check = p.funds.map((e) => {
          if (e.fund === undefined) console.log("Escluso questo id ", p.id);
          return e.fund === undefined;
        });
        if (check.includes(true)) {
          return false;
        }
        return true;
      });
    },
    portfolioInViewChange: (
      state,
      action: PayloadAction<{ portfolio: Portfolio }>
    ) => {
      let p = { ...action.payload.portfolio };
      if (p.funds.length > 0) {
        p.history = {
          start: maxNumber(
            action.payload.portfolio.funds.map((e) => e.fund.history.start)
          ),
          end: minNumber(
            action.payload.portfolio.funds.map((e) => e.fund.history.end)
          ),
        };
      } else {
        p.history = {
          start: 0,
          end: 0,
        };
      }
      if (p.type === "money") {
        let s = Sum(p.funds.map((e) => e.money));
        p.funds = p.funds.map((e) => {
          return { ...e, percentage: e.money / s };
        });
      }

      state.view.portfolio = p;
      //console.log(state.view)
    },
    resetMakerPortfolio: (state) => {
      state.create.portfolio = placeholderNewPortfolio;
    },
    setLastidCreated: (state, action: PayloadAction<{ id: string }>) => {
      state.create.lastidCreated = action.payload.id;
      if (state.view.portfolio != null) {
        state.view.portfolio.id = action.payload.id;
      }
    },
    rebalancePercentageViewPortfolio: (state) => {
      if (state.view.portfolio) {
        let weights = percentageFundRebalance(state.view.portfolio);
        state.view.portfolio.funds.forEach((e, index) => {
          e.percentage = weights[index];
        });
        console.log("ribilanciato", weights);
      }
    },
    deletePortfolio(state, action: PayloadAction<{ id: string }>) {
      state.portfolios = state.portfolios.filter(
        (e) => e.id !== action.payload.id
      );
    },
    portfolioError(
      state,
      actiion: PayloadAction<{ id: string; error: JsonResponseError }>
    ) {
      state.portfolios.forEach((e) => {
        if (e.id === actiion.payload.id) {
          e.error = actiion.payload.error;
        }
      });
    },
  },
  initialState: _init,
});

export const PortfolioActions = portfolioStore.actions;
export const PortfolioReducer = portfolioStore.reducer;
