import { Card } from "@blueprintjs/core";
import { Row } from "components/atoms/layout";
import React from "react";

export const ContactsPage = () => {
  return (
    <div>
      <br />
      <Row mainAxisAlignment="center">
        <Card>
          <h3>Contatti</h3>
          <br />
          Email:{" "}
          <a href="mailto:support@empirich.com">
            <b>support@empirich.com</b>
          </a>
          <br />
          Tel:{" "}
          <a href="tel:+39 3519967409">
            <b>+39 3519967409</b>
          </a>
          <br />
          <br />
          <span>
            Il servizio di assistenza è operativo dal <b>Lunedì</b> al{" "}
            <b>Venerdì</b> <br /> dalle ore <b>9:00</b> alle ore <b>18:00</b>
            <br />
          </span>
        </Card>
      </Row>
    </div>
  );
};
