import Footer from "components/atoms/footer";
import { childrenprops } from "components/types";
import { RootState } from "contorller";
import { useAnalytics } from "contorller/analytics/analytics";
import { AuthState } from "contorller/auth/reducer";
import React from "react";
import { useSelector } from "react-redux";
import { NavEmpirich } from "./SubscriptionDialog/navbar";

export const ScaffoldPage = (props: childrenprops) => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const an = useAnalytics();
  if (auth.user) {
    an.sessionTraceUser();
  }
  return (
    <React.Fragment>
      <NavEmpirich />
      {props.children}
      <Footer />
    </React.Fragment>
  );
};
