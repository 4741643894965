import { config, firebaseApp } from "config";
import { JsonResponseError, store } from "contorller";
import { AuthActions, Product } from "./reducer";
import {
  AuthController,
  Auther,
  requestSubscriptionParams,
  signResponse,
  signUpRequest,
  User,
} from "./types";

export const authController = (): AuthController => {
  return ConcreteAuthController.instance();
};

// Initialize Firebase
const app = firebaseApp();

class ConcreteAuthController implements AuthController {
  private user: User | null;

  private constructor() {
    let data = localStorage.getItem("user");
    if (data === null) {
      this.user = null;
    } else {
      let result: User = JSON.parse(data);
      this.user = result;
      store.dispatch(AuthActions.signin({ user: result }));
    }
  }

  private async refreshToken(): Promise<void> {
    const response = await fetch(config().URL + "/users/token", {
      method: "GET",
      headers: await this.getAuther().getHeaderToken(),
    });

    if (response.status === 200) {
      const data: signResponse = await response.json();
      await app.auth().signInWithCustomToken(data.token);
      console.log("refresh token ", data.token, data.user);
      store.dispatch(AuthActions.signin({ user: data.user }));
    } else {
      const data: JsonResponseError = await response.json();
      console.error(data);
      throw new Error("Errore in token refresh");
    }
  }

  private static insta: ConcreteAuthController;
  static instance(): ConcreteAuthController {
    if (!ConcreteAuthController.insta) {
      ConcreteAuthController.insta = new ConcreteAuthController();
      ConcreteAuthController.insta.setup();
    }
    return ConcreteAuthController.insta;
  }

  async requestListCodesProducts(): Promise<void> {
    const response = await fetch(config().URL + "/users/products");
    if (response.status === 200) {
      const data: Product[] = await response.json();
      console.log(data);
      store.dispatch(AuthActions.listProducts({ products: data }));
    } else {
      const err: JsonResponseError = await response.json();
      console.error(err.code, err.error);
    }
  }
  async requestSubscription(params: requestSubscriptionParams): Promise<void> {
    if (params.error) {
      store.dispatch(
        AuthActions.errorPayment({
          code: "card-error-0001",
          error: params.error.message || "Sconosciuto",
          status: "error",
        })
      );
    } else {
      // fetch request sub
      const response = await fetch(config().URL + "/users/pay", {
        body: JSON.stringify({
          paymentMethod: params.paymentMethod,
          productId: params.productId,
          priceId: params.priceId,
        }),
        headers: await this.getAuther().getHeaderToken(),
        method: "POST",
      });
      if (response.status === 200) {
        const data: signResponse = await response.json();
        await app.auth().signInWithCustomToken(data.token);
        store.dispatch(AuthActions.signin({ user: data.user }));
      } else {
        const data: JsonResponseError = await response.json();
        console.error(data.code, data.error);
      }
    }

    return;
  }

  async getInfo(token: string): Promise<void> {
    console.log("[get user info]");
    const response = await fetch(config().URL + "/users/", {
      headers: { Authorization: "firebase " + token },
      method: "GET",
    });
    if (response.status === 200) {
      const data: signResponse = await response.json();
      this.user = data.user;
      localStorage.setItem("user", JSON.stringify(this.user));
      store.dispatch(AuthActions.signin({ user: data.user }));
      if (data.session.subscription !== data.user.deadlineSubscription) {
        console.log(`
                session: ${new Date(data.session.subscription * 1000)} 
                user:    ${new Date(data.user.deadlineSubscription * 1000)}
                >>Non c'è consistenza nella sottoscrizione viene avviato un refresh token
                `);
        this.refreshToken();
      }
    } else {
      const data: JsonResponseError = await response.json();
      this.user = null;
      localStorage.removeItem("user");
      await this.signOut();
      store.dispatch(AuthActions.error({ error: data, user: undefined }));
    }
  }

  private async setup(): Promise<void> {
    store.dispatch(AuthActions.isFething());
    app.auth().onAuthStateChanged(async (u) => {
      if (u !== undefined && u !== null) {
        store.dispatch(AuthActions.isFething());
        const token = await u.getIdToken();
        console.log("token", token);
        await this.getInfo(token);
      } else {
        localStorage.removeItem("user");
        store.dispatch(AuthActions.signout());
      }
      store.dispatch(AuthActions.load());
    });
  }

  isOnline(): boolean {
    return this.user === null ? false : true;
  }

  async signInWithCode(code: string): Promise<void> {
    const response = await fetch(config().URL + "/users/legacy/signin/" + code);
    if (response.status === 200) {
      const data: signResponse = await response.json();
      store.dispatch(AuthActions.isFething());
      localStorage.setItem("user", JSON.stringify(data.user));
      try {
        await app.auth().signInWithCustomToken(data.token);
      } catch (e) {
        console.error("FirebaseError: ", e);
        store.dispatch(
          AuthActions.error({
            error: { error: "Errore imprevisto nel login. " },
          })
        );
        return;
      }
      store.dispatch(AuthActions.signin({ user: data.user }));
    } else {
      const data: JsonResponseError = await response.json();
      localStorage.removeItem("user");
      store.dispatch(AuthActions.error({ error: data }));
    }
  }

  async signIn(email: string, password: string): Promise<void> {
    store.dispatch(AuthActions.isFething());
    const response = await fetch(config().URL + "/users/signin", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    if (response.status === 200) {
      const data: signResponse = await response.json();
      store.dispatch(AuthActions.isFething());
      localStorage.setItem("user", JSON.stringify(data.user));
      try {
        await app.auth().signInWithCustomToken(data.token);
      } catch (e) {
        console.error("FirebaseError: ", e);
        store.dispatch(
          AuthActions.error({
            error: { error: "Errore imprevisto nel login. " },
          })
        );
        return;
      }
      store.dispatch(AuthActions.signin({ user: data.user }));
    } else {
      const data: JsonResponseError = await response.json();
      localStorage.removeItem("user");
      store.dispatch(AuthActions.error({ error: data }));
    }
  }

  async signUp(request: signUpRequest): Promise<void> {
    store.dispatch({ type: "auth/fetch" });
    console.log(request);
    const response = await fetch(config().URL + "/users/signup", {
      method: "POST",
      body: JSON.stringify(request),
    });

    if (response.status === 200) {
      const data: signResponse = await response.json();
      store.dispatch(AuthActions.isFething());
      localStorage.setItem("user", JSON.stringify(data.user));
      await app.auth().signInWithCustomToken(data.token);
      store.dispatch(AuthActions.signin({ user: data.user }));
    } else {
      const data: JsonResponseError = await response.json();
      localStorage.removeItem("user");
      store.dispatch(AuthActions.error({ error: data }));
    }
  }

  async signOut(): Promise<void> {
    await app.auth().signOut();
    localStorage.removeItem("user");
    store.dispatch(AuthActions.signout());
  }

  async update(user: User): Promise<void> {
    store.dispatch(AuthActions.isFething());
    const response = await fetch(config().URL + "/users/update", {
      method: "POST",
      body: JSON.stringify(user),
      headers: await this.getAuther().getHeaderToken(),
    });
    if (response.status === 200) {
      const data: User = await response.json();
      store.dispatch({ type: "auth/update", user: data });
    } else {
      const data: JsonResponseError = await response.json();
      store.dispatch(AuthActions.error({ error: data }));
    }
  }

  async revoke(): Promise<void> {
    const response = await fetch(config().URL + "/users/revoke", {
      method: "GET",
      headers: await this.getAuther().getHeaderToken(),
    });

    if (response.status === 200) {
      const token = await this.getAuther().getToken();
      console.log("rivocato", await response.json());
      await this.getInfo(token);
    } else {
      const data: JsonResponseError = await response.json();
      store.dispatch(AuthActions.error({ error: data }));
    }
  }

  async hook(): Promise<void> {
    if (this.user === null) throw new Error("Devi essere online");
    if (this.user === null) throw new Error("Devi essere online");
    const request = {
      sourceId: this.user.uid,
      subscriptionType: "month",
    };
    const response = await fetch(config().URL + "/users/hook", {
      method: "POST",
      body: JSON.stringify(request),
    });

    if (response.status === 200) {
      await this.refreshToken();
    } else {
      const data: JsonResponseError = await response.json();
      console.error(data, request);
      //   throw new Error(JSON.stringify(data))
    }
  }

  getUser(): User | undefined {
    return store.getState().auth.user;
  }

  private async getToken(refresh?: boolean): Promise<string> {
    const t = await app.auth().currentUser?.getIdToken(refresh);
    if (t) {
      return t;
    }
    console.log("[try token fb]", t);

    throw new Error("Non sei loggato");
  }
  getAuther(): Auther {
    return {
      getToken: async () => await this.getToken(),
      getHeaderToken: async () => {
        return { Authorization: "firebase " + (await this.getToken()) };
      },
      isAdmin: () => this.user?.admin || false,
    };
  }
}
