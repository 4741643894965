import Papa from "papaparse";
import { Fund } from "./fund/redux";
import { PortfolioFund } from "./portfolio/types";
import { myFormatDate } from "./simulations/PIC/fx";
import {
  FundWeight,
  Investment,
  PACResponse,
  PICResponse,
  Snap,
} from "./simulations/types";

export function convertPortfolioFundToFundWeight(f: PortfolioFund): FundWeight {
  return {
    end: f.fund.history.end,
    start:
      f.fund.history.proxy && f.proxed
        ? f.fund.history.proxy
        : f.fund.history.start,
    isin: f.fund.isin,
    id: f.id,
    name: f.fund.name,
    weight: f.percentage,
  };
}

export function convertPortfolioToFundWeight(
  funds: PortfolioFund[]
): FundWeight[] {
  return funds.map<FundWeight>((f) => convertPortfolioFundToFundWeight(f));
}

export function convertFundToFundWeight(fund: Fund): FundWeight {
  // TO FIX.
  const history = fund.history;

  return {
    isin: fund.isin,
    id: fund.id,
    name: fund.name,
    start: history.proxy ? history.proxy : history.start,
    end: history.end,
    weight: 0,
  };
}

export function maxNumber(nums: number[]): number {
  if (nums.length === 0) return 0; // throw new Error("devi passarmi un array di numeri con almeno un elemento")
  const result = nums.sort((p, q) => q - p);
  return result[0];
}

export function minNumber(nums: number[]): number {
  if (nums.length === 0) return 0; // throw new Error("devi passarmi un array di numeri con almeno un elemento")
  const result = nums.sort((p, q) => p - q);
  return result[0];
}

export function convertFundToPortfolioFund(
  fund: Fund,
  shouldEnableProxy: boolean = true
): PortfolioFund {
  return {
    fund,
    id: fund.id,
    quote: 0,
    money: fund.amount || 0,
    percentage: 0,
    proxed:
      fund.history.proxy !== undefined &&
      fund.history.proxy !== 0 &&
      shouldEnableProxy
        ? true
        : false,
  };
}

export function isISIN(value: string): boolean {
  if (!value) return false;
  return importIsinString(value).length === 1;
}
export function importIsinString(isins: string): string[] {
  //FR0000133308
  const result: string[] = [];
  for (let i of isins.matchAll(/[A-Z]{2}[A-Z-0-9]{9}[0-9]{1}/g)) {
    //console.log(i)
    result.push(i[0]);
  }
  return result;
}

function parseAmount(amount: any): any {
  if (typeof amount === "number") {
    return amount;
  }
  // First let's remove any currency sign.
  return parseFloat(
    (amount || "0")
      .replace(/$|€|£|¥/gi, "")
      .replace(/\./g, "")
      .replace(/,/g, ".")
      .trimEnd()
  );
}

interface medFund {
  isin: string;
  amount: number;
}
export function parseMediolanumInput(value: any): medFund[] {
  // First trim the start and the end to avoid lines misleading.
  const input: any = fixMediolanumFormat(value);
  const result: any = Papa.parse(input, {
    delimiter: "\t",
    header: true,
  });

  // Get the two isin and amount columns.
  const isinColumn: any = result.meta.fields.find(
    (c: any) => c.indexOf("ISIN") >= 0
  );
  const amountColumn: any = result.meta.fields.find(
    (c: any) => c.indexOf("CONTROVALORE") >= 0
  );
  // Safe check.
  if (isinColumn < 0 || amountColumn < 0) {
    throw new Error("Cannot find isin or amount colums");
  }
  // Let's get all the values we need.
  const parsedResults = result.data.map((f: any) => ({
    isin: f[isinColumn],
    amount: parseAmount(f[amountColumn]),
  }));

  // Now we have to check if the fund is available.
  return (parsedResults as medFund[]).filter((e) => isISIN(e.isin));
}

function fixMediolanumFormat(input: any) {
  return input
    .split(/\r?\n/)
    .filter((p: any) => p.indexOf(" ▼") < 0)
    .reduce((prev: any, next: any, index: any, arr: any) => {
      // Only on 0 or odd indexes.
      if (index % 2 === 0) {
        return [...prev, next + "\t" + arr[index + 1]];
      } else {
        return prev;
      }
    }, [])
    .map(function (l: any) {
      return l.trimStart();
    })
    .join("\n")
    .trimStart()
    .trimEnd();
}

export function importFromExcelFile(rows: string | number[][]): medFund[] {
  const result: medFund[] = [];
  for (let row of rows) {
    if (!row[0] || !row[1]) continue;

    if (isISIN(row[0].toString())) {
      result.push({
        isin: row[0].toString(),
        amount: parseFloat(row[1].toString()) || 0,
      });
    }
  }
  console.log("import excel result", result);
  return result;
}

export function getDays(start: Date, end: Date): number {
  if (start.getTime() > end.getTime())
    throw new Error("non puoi passarmi lo start superiore all'end");

  let hours = Math.abs((start.getTime() - end.getTime()) / 1000 / 60 / 60);
  return hours / 24;
}

export function unixToDate(seconds: number): Date {
  return new Date(seconds * 1000);
}

export function dateToUnix(date: Date): number {
  return parseInt((date.getTime() / 1000).toFixed());
}

export function picToPac(pic: PICResponse): PACResponse {
  const i = pic.snaps.map<Investment>((e) => {
    return {
      unix: e.unix,
      money: 0,
    };
  });

  return {
    index: 0,
    total: 0,
    simulationsWithSpots: [],
    annual: 0,
    absolute: 0,
    request: {
      ...pic.request,
      monthFrequency: 1,
      initialInvestment: pic.request.initialInvestment.money,
      funds: pic.request.funds,
      rate: 0,
      differimentMounth: 0,
      days: [1],
      durationInvestment: 1,
      start: pic.snaps[0].unix,
      end: pic.snaps[pic.snaps.length - 1].unix,
    },
    snaps: pic.snaps,
  };
}

export function cutSnaps(snaps: Snap[], start?: Date, end?: Date): Snap[] {
  let startIndex = 0;
  let endIndex = snaps.length;

  if (start || end) {
    let story = snaps.map<string>((e) => myFormatDate(new Date(e.unix * 1000)));

    if (start) {
      startIndex = story.indexOf(myFormatDate(start));
      if (startIndex === -1) {
        throw new Error("non trovo lo start che mi hai indicato.");
      }
    }
    if (end) {
      endIndex = story.indexOf(myFormatDate(end));
      if (endIndex === -1) {
        throw new Error("non trovo end che mi hai indicato.");
      }
    }
    if (startIndex > endIndex) {
      throw new Error(
        "stai provando a tagliare la storia indicando la fine del taglio in data precedente all'inizio del taglio."
      );
    }
  }

  return snaps.slice(startIndex, endIndex);
}

export function getPercentage(start: number, end: number): number {
  return parseFloat(((start / end) * 100 - 100).toFixed(3));
}

export function annualEarge(ctu: number, cti: number, days: number): number {
  let a = ctu / cti;
  let y = parseInt((days / 365).toFixed(2));
  let g = 1 / (y === 0 ? 1 : y);
  if (isNaN(a)) a = 0;
  return Math.pow(a, g) - 1;
}

export function absoluteEarge(ctu: number, cti: number): number {
  return ctu / cti - 1;
}

export function openJsonDownload(data: any) {
  let something = window.open(
    "data:text/json," + encodeURIComponent(JSON.stringify(data)),
    "_blank"
  );
  if (something) something.focus();
}

export function Int(n: number): number {
  return parseInt(n.toString());
}
