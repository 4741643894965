import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonResponseError } from "contorller";
import { User } from "./types";


export interface Product {
    id: string
    active: true
    product: { id: string }
    recurring: {
        interval_count: number
        trial_period_days: number
        interval: "month" | "year"
    }
    unit_amount: number
    unit_amount_decimal: string
}

export interface AuthState {
    user?: User
    error?: JsonResponseError
    appIsLoad: boolean
    isLogged: boolean
    isFething: boolean
    subscriptionIsFetching: boolean
    loadProducts: boolean
    errorSubscription?: JsonResponseError
    products: Product[]
}


const authState = createSlice({
    name: "auth",
    reducers: {
        load: (state) => { state.appIsLoad = true; },
        signin: (state, action: PayloadAction<{ user: User }>) => {
            state.user = action.payload.user;
            state.isLogged = true;
            state.isFething = false;
            state.error = undefined;
        },
        signout: (state) => {
            state.user = undefined;
            state.isFething = false;
            state.isLogged = false;
            state.error = undefined;
        },
        error: (state, action) => {
            if (!action.payload) throw new Error("devi passarmi il payload con error")
            state.isFething = false;
            state.error = action.payload.error
        },
        isFething: (state) => {
            state.isFething = true;
            state.error = undefined;
        },
        listProducts: (state, action: PayloadAction<{ products: Product[] }>) => {
            state.products = action.payload.products
            state.loadProducts = true
        },
        fetchRequestSubscription: (state) => {
            state.subscriptionIsFetching = true
        },
        donePayment: (state) => {
            state.errorSubscription = undefined
            state.subscriptionIsFetching = false
        },
        errorPayment: (state, action: PayloadAction<JsonResponseError>) => {
            state.errorSubscription = action.payload
            state.subscriptionIsFetching = false
        }

    },
    initialState: {
        appIsLoad: false,
        isLogged: false,
        products: [],
        loadProducts: false,
        subscriptionIsFetching: false,
        isFething: false,
    } as AuthState
})

export const authReducer = authState.reducer
export const AuthActions = authState.actions