import { Expander, H6 } from "@blueprintjs/core";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "../layout";
import { Logo } from "../logo";
import "./index.scss";
import LogoFida from "./Logo-Fida-Wide.png";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div>
      <Row>
        <div className="footer-details">
          <H6>{t("Note metodologiche")}</H6>
          <ul>
            <li>
              {t(
                "Le serie storiche degli strumenti a distribuzione vengono elaborate, a cura di FIDA, tenendo conto del reinvestimento dei proventi."
              )}
            </li>
            <li>
              {t(
                "Tutte le serie storiche sono valorizzate in Euro, a cura di FIDA; anche per strumenti denominati in altra valuta."
              )}
            </li>
            <li>
              {t(
                "Al momento la piattaforma permette di osservare tutti i fondi e sicav Italiani ed esteri autorizzati alla vendita in Italia sia in classe retail che istituzionale e la totalità degli ETF quotati alla Borsa Valori Italiana."
              )}
            </li>
            <li>
              {t(
                "Per i valori precedenti la data di partenza, indicata sulla scheda Dati Fondo, la serie storica è ricostruita secondo il metodo proprietario FIDA proxy a 5 livelli. Per ulteriori informazioni clicca "
              )}
              <a target="_blank" href="/FIDAProxyS.PDF">
                {t("qui")}
              </a>
              .
            </li>
          </ul>
        </div>
        <div className="footer-details">
          <H6>{t("Osservare per comprendere")}</H6>
          <p>
            {t(
              "La regolarità di comportamento di un portafoglio aumenta al crescere della diversificazione e della durata d'investimento ipotizzati."
            )}
          </p>
          <H6>{t("Disclaimer")}:</H6>
          <p>
            {t(
              "Le informazioni offerte non intendono in alcun modo costituire consulenza finanziaria, sollecitazione al pubblico risparmio o promuovere alcuna forma d'investimento. Eventuali decisioni operative che dovessero essere prese dai lettori, sulla base dei dati, analisi e grafici qui forniti, i quali hanno carattere puramente informativo ed orientativo, sono assunte in piena autonomia decisionale e a proprio esclusivo rischio."
            )}
          </p>
        </div>
      </Row>
      <Row mainAxisAlignment="center">
        <div className="footer-logo">
          <p>{t("Dati by")}</p>
          <img src={LogoFida} height={30} width={144} alt="Fida Logo" />
        </div>
        <Expander />
        <div className="copyright-text">
          <div>
            © {new Date().getFullYear()} Empirich Srl - All rights reserved
          </div>
        </div>
        <Expander />
        <div className="footer-logo">
          <Logo size="medium" color="dark" />
        </div>
      </Row>
    </div>
  );
}
