import { childrenprops } from "components/types"
import { RootState } from "contorller"
import { AuthState } from "contorller/auth/reducer"
import React from "react"
import { useSelector } from "react-redux"




export const OnlyAdmin = (props:childrenprops) => {
    const auth = useSelector<RootState,AuthState>(state=>state.auth)

    if (!auth.user) return <React.Fragment />
    if (auth.user.admin===false) return <React.Fragment/>

    return <React.Fragment>
        {props.children}
    </React.Fragment>

}