import React, { CSSProperties } from "react";
import "./logo.scss";

interface logoProps {
  size: "large" | "medium" | "small" | "auto";
  color: "dark" | "light";
  banco?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}

export const Logo = (props: logoProps) => {
  return (
    <div
      style={props.style}
      onClick={props.onClick}
      className={`logo 
        ${props.color || "light"}
        ${props.size || "medium"}
        ${props.banco ? "banco" : ""}

        `}
    ></div>
  );
};
