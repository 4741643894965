import { Button } from "@blueprintjs/core";
import { InputGroup } from "@blueprintjs/core";
import React, { useState } from "react";


export interface signUpData {
    name: string;
    surname: string;
    email: string;
    password: string;
    birthday: string;
    phone: string;
    pec: string;
    fiscalCode: string
    vatNumber: string
    sdi: string
    address: {
        cap: string
        city: string
        region: string
        province: string
        street: string
        nation: string
    }
}

interface SignUPProps {
    onSignUp: (data: signUpData) => Promise<void>;
}

export const SignupForm = (props: SignUPProps): JSX.Element => {


    const [error, setError] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false);
    const email = React.createRef<HTMLInputElement>();
    const psw1 = React.createRef<HTMLInputElement>();
    const psw2 = React.createRef<HTMLInputElement>();
    const birthday = React.createRef<HTMLInputElement>();
    const name = React.createRef<HTMLInputElement>();
    const surname = React.createRef<HTMLInputElement>();

    const phone = React.createRef<HTMLInputElement>();
    const pec = React.createRef<HTMLInputElement>();
    const fiscalCode = React.createRef<HTMLInputElement>();
    const vatNumber = React.createRef<HTMLInputElement>();
    const sdi = React.createRef<HTMLInputElement>();

    const cap = React.createRef<HTMLInputElement>();
    const city = React.createRef<HTMLInputElement>();
    const region = React.createRef<HTMLInputElement>();
    const province = React.createRef<HTMLInputElement>();
    const street = React.createRef<HTMLInputElement>();
    const nation = React.createRef<HTMLInputElement>();


    const signup = () => {
        if (error === "")
            if (email.current && psw1.current && birthday.current && psw2.current &&
                surname.current && name.current && phone.current &&
                pec.current && sdi.current && vatNumber.current &&
                fiscalCode.current && city.current && province.current &&
                street.current && nation.current && region.current &&
                cap.current) {
                setLoading(true)
                props.onSignUp({
                    name: name.current.value,
                    surname: surname.current.value,
                    email: email.current.value,
                    password: psw1.current.value,
                    birthday: birthday.current.value,
                    pec: pec.current.value,
                    phone: phone.current.value,
                    fiscalCode: fiscalCode.current.value,
                    vatNumber: vatNumber.current.value,
                    sdi: sdi.current.value,
                    address: {
                        cap: cap.current.value,
                        city: city.current.value,
                        region: region.current.value,
                        province: province.current.value,
                        street: street.current.value,
                        nation: nation.current.value,
                    }
                }).finally(() => { setLoading(false) })
            } else {
                throw new Error("Errore qualche campo non è validato")
            }
    }

    const checkPassword = () => {
        if (psw1 && psw2) {
            if (psw1.current?.value !== psw2.current?.value) {
                if (psw2.current?.value !== "" && psw1.current?.value !== "")
                    setError("Le password non coincidono")
            } else {
                if (error !== "") {
                    setError("")
                }
            }
        }
    }


    return <React.Fragment>
        <InputGroup inputRef={name} placeholder="Il tuo nome" type="text" />
        <InputGroup inputRef={surname} placeholder="Il tuo cognome" type="text" />
        <InputGroup inputRef={email} placeholder="email" type="email" />
        <InputGroup inputRef={psw1} onChange={() => checkPassword()} placeholder="password" type="password" />
        <InputGroup inputRef={psw2} onChange={() => checkPassword()} placeholder="ripeti password" type="password" />
        <InputGroup inputRef={birthday} placeholder="Data di nascita" type="date" />
        <InputGroup inputRef={phone} placeholder="Telefono" type="text" />

        <hr />
        <h4>Fatturazione</h4>
        <InputGroup inputRef={pec} placeholder="PEC" type="text" />
        <InputGroup inputRef={fiscalCode} placeholder="Codice fiscale" type="text" />
        <InputGroup inputRef={vatNumber} placeholder="Partita Iva" type="text" />
        <InputGroup inputRef={sdi} placeholder="Codice destinatario" type="text" />

        <hr />
        <h4>Indirizzo</h4>

        <InputGroup inputRef={nation} placeholder="Nazione" type="text" />
        <InputGroup inputRef={region} placeholder="Regione" type="text" />
        <InputGroup inputRef={province} placeholder="Provincia" type="text" />
        <InputGroup inputRef={city} placeholder="Città" type="text" />
        <InputGroup inputRef={street} placeholder="Via" type="text" />
        <InputGroup inputRef={cap} placeholder="CAP" type="text" />

        <br />

        <Button intent="success" loading={loading} onClick={signup} fill >Registrati</Button>
    </React.Fragment>
}

