import { Dialog, IDialogProps } from "@blueprintjs/core";
import React, {
  createContext,
  Fragment,
  useContext,
  useMemo,
  useState,
} from "react";

//TODO: da spostare in "organismi"

interface ModalCtx {
  open(
    children: JSX.Element | (() => JSX.Element),
    opts?: Partial<IDialogProps>
  ): void;
  close(): void;
}

const ModalContext = createContext<ModalCtx | undefined>(undefined);

export const Modal = (props: { children: any }) => {
  const [show, setShow] = useState(false);
  const [opts, setOpts] = useState<Partial<IDialogProps>>({});
  const [children, setChildren] = useState(<Fragment />);
  const m: ModalCtx = useMemo<ModalCtx>(
    () => ({
      open: (children, opts) => {
        setOpts(opts || {});
        setShow(true);
        setChildren(children);
      },
      close: () => {
        setChildren(<Fragment />);
        setOpts({});
        setShow(false);
      },
    }),
    []
  );

  return (
    <ModalContext.Provider value={m}>
      <Dialog
        isOpen={show}
        isCloseButtonShown
        canEscapeKeyClose
        onClose={m.close}
        {...opts}
      >
        {children}
      </Dialog>
      {props.children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalCtx => {
  const modal = useContext(ModalContext);
  if (modal === undefined) {
    throw new Error("devi usare <Modal/>");
  }
  return modal;
};
