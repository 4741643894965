import {
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
} from "@blueprintjs/core";
import { RootState } from "contorller";
import { authController } from "contorller/auth/controller";
import { AuthState } from "contorller/auth/reducer";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./scss/profile-menu.scss";

export const ProfileMenu = () => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  if (!auth.user)
    return (
      <Link to="/signin">
        <Button className="button-nav" minimal>
          Login
        </Button>
      </Link>
    );

  const controller = authController();

  const button = (
    <Button className="button-nav" minimal>
      <div className="row-profile">
        {" "}
        <span>
          {auth.user?.name} {auth.user?.surname}
        </span>{" "}
        <div className="profile"></div>{" "}
      </div>
    </Button>
  );
  // NOTE: questo menu è stato volutamente oscurato nello sprint S39
  if (auth.user && auth.user.admin === false) {
    return button;
  }

  const exampleMenu = (
    <Menu>
      <Link to="/settings">
        <MenuItem icon="settings" text="Impostazioni" />
      </Link>
      <MenuItem icon="symbol-square" text="Fatturazione" />
      <MenuDivider />
      <MenuItem
        icon="log-out"
        text="Logout"
        onClick={() => {
          controller.signOut();
        }}
        disabled={false}
      />
    </Menu>
  );
  return (
    <Popover content={exampleMenu} position="left-bottom">
      {button}
    </Popover>
  );
};
