import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Portfolio } from "contorller/portfolio/types";

export interface ConfrontableState {
  groups: Portfolio[];
  loading: boolean;
}

const comparisonState = createSlice({
  name: "confrontable",
  initialState: {
    loading: false,
    groups: [],
  } as ConfrontableState,
  reducers: {
    cleanAllPortfolio: (state) => {
      state.groups = [];
    },
    addPortfolio: (state, action: PayloadAction<{ portfolio: Portfolio }>) => {
      const { portfolio } = action.payload;
      state.groups.push({
        ...portfolio,
        type: "percentage",
      });
    },
    updatePortfolio: (
      state,
      action: PayloadAction<{ portfolio: Portfolio }>
    ) => {
      const { portfolio } = action.payload;
      const previousIndex = state.groups.findIndex(
        (d) => d.id === portfolio.id
      );
      if (previousIndex >= 0) {
        state.groups[previousIndex] = portfolio;
      }
    },
    removePortfolio: (state, action: PayloadAction<{ ID: string }>) => {
      state.groups = state.groups.filter((e) => e.id !== action.payload.ID);
    },
    portfolioError: (
      state,
      action: PayloadAction<{ id: string; error: string }>
    ) => {
      for (let i of state.groups) {
        if (i.id === action.payload.id) {
          i.error = { error: action.payload.error, code: "", status: "" };
          break;
        }
      }
    },
  },
});

export const ConfrontableActions = comparisonState.actions;
export const ConfrontableReducer = comparisonState.reducer;
