import { ReactiveBase } from "@appbaseio/reactivesearch";
import { NonIdealState, Position, Spinner, Toaster } from "@blueprintjs/core";
import { ScaffoldPage } from "components/organismes/scaffoldPage";
import { AdminPage } from "components/page/admin";
import { ContactsPage } from "components/page/contacts";
import LegacyLoginPage from "components/page/legacy";
import { LoginPage } from "components/page/loginPage";
import { LogoutPage } from "components/page/logout.page";
import { SignUpPage } from "components/page/signupPage";
import { config } from "config";
import { RootState } from "contorller";
import { authController } from "contorller/auth/controller";
import { User } from "contorller/auth/types";
import i18n from "i18next";
import initHttpI18next from "i18next-http-backend";
import { compact, set, update } from "lodash";
import React, { useCallback } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./app.css";
import "./index.scss";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(initHttpI18next)
  .init({
    lng: "es",
  });

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
  position: Position.TOP,
});

export function ReactiveProvider(p: {
  children: JSX.Element;
  customQueryTransform?: (query: any) => any;
}) {
  const user = useSelector<RootState, User | undefined>(
    (state) => state.auth.user
  );

  const { admin: isAdmin, uid } = user ?? { admin: false, uid: "" };

  const transformResponse = useCallback(
    async (response: any, componentId: string) => {
      const hits = response?.hits.hits ?? [];
      const notPrivateHits = hits.filter((p: any) => {
        const isPrivate = p._source.private != null ? isAdmin : true;
        const isPortfolioViewer =
          "viewers" in p._source ? p._source.viewers.includes(uid) : true;
        return isPortfolioViewer;
      });

      return {
        ...response,
        hits: {
          ...response.hits,
          hits: notPrivateHits,
        },
      };
    },
    [uid, isAdmin]
  );

  const transformRequest = useCallback(
    async (request: any) => {
      request.headers.Authorization =
        "firebase " + (await authController().getAuther().getToken());
      const [preference, body] = request.body.split("\n");
      try {
        let query = JSON.parse(body);
        if ("aggs" in query) {
          return request;
        }
        if (p.customQueryTransform != null) {
          query = p.customQueryTransform(query);
        } else {
          update(query, "query.bool.must", (prev) =>
            compact([
              ...prev,
              !isAdmin
                ? {
                    bool: {
                      should: [
                        {
                          term: {
                            private: false,
                          },
                        },
                        {
                          bool: { must_not: { exists: { field: "private" } } },
                        },
                      ],
                    },
                  }
                : null,
              {
                exists: {
                  field: "history",
                },
              },
              {
                bool: {
                  must_not: {
                    term: {
                      "history.start": 0,
                    },
                  },
                },
              },
              {
                bool: {
                  must_not: {
                    term: {
                      codeMarket: 171,
                    },
                  },
                },
              },
              {
                bool: {
                  should: [
                    {
                      bool: {
                        must: [
                          {
                            exists: {
                              field: "viewers",
                            },
                          },
                          {
                            match: {
                              "viewers.keyword": {
                                query: uid,
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      bool: {
                        must_not: {
                          exists: {
                            field: "viewers",
                          },
                        },
                      },
                    },
                  ],
                },
              },
            ])
          );
        }

        const nextReqBody = [preference, JSON.stringify(query), ""].join("\n");
        return set(request, "body", nextReqBody);
      } catch {}
      return request;
    },
    [uid, isAdmin, p.customQueryTransform]
  );

  return (
    <ReactiveBase
      app="funds-v2,portfolios-v2"
      url={config().ES}
      transformResponse={transformResponse}
      transformRequest={transformRequest}
    >
      {p.children}
    </ReactiveBase>
  );
}

function App() {
  if (config().MAINANTENCE_MODE) {
    return (
      <div className="flex-grow flex items-center">
        <NonIdealState
          title="Sito in manutenzione"
          icon="build"
          description="Empirich è in manutenzione, sarà nuovamente online al più presto!"
        />
      </div>
    );
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <I18nextProvider i18n={i18n}>
        <Pages />
      </I18nextProvider>
    </React.Suspense>
  );
}

export default App;

const LazySubscriptionDialog = React.lazy(
  () => import("components/organismes/SubscriptionDialog")
);
const LazyMesPage = React.lazy(() => import("components/page/mes"));
const LazyHomePage = React.lazy(() => import("components/page/home"));
const LazySharePage = React.lazy(() => import("components/page/share"));
const LazyPortfolioPage = React.lazy(() => import("components/page/portfolio"));
const LazyPortfoliosPage = React.lazy(
  () => import("components/page/portfolios")
);
const LazySettingPage = React.lazy(() => import("components/page/settings"));
const LazyPageConfrontable = React.lazy(
  () => import("components/page/confrontable")
);

const Pages = () => {
  return (
    <BrowserRouter>
      <ScaffoldPage>
        <React.Suspense fallback={<React.Fragment />}>
          <LazySubscriptionDialog />
        </React.Suspense>
        <Switch>
          <Route path="/" exact component={LazyHomePage} />
          <Route exact path="/s/:code" component={LazySharePage} />
          <Route exact path="/transfer/:code" component={LegacyLoginPage} />
          <Route path="/signin" component={LoginPage} />
          <Route path="/signup" component={SignUpPage} />
          <Route path="/logout" component={LogoutPage} />
          <Route path="/mes" component={LazyMesPage} />
          <Route path="/portfolio/:id" component={LazyPortfolioPage} />
          <Route path="/contacts" component={ContactsPage} />
          <Route path="/portfolios" component={LazyPortfoliosPage} />
          <Route path="/portfolio" component={LazyPortfolioPage} />
          <Route path="/fund/:id" component={LazyPortfolioPage} />
          <Route path="/settings" component={LazySettingPage} />
          <Route path="/compare">
            <ReactiveProvider>
              <LazyPageConfrontable />
            </ReactiveProvider>
          </Route>
          <Route path="/admin" component={AdminPage} />
        </Switch>
      </ScaffoldPage>
    </BrowserRouter>
  );
};
