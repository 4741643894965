import { Button, Card, InputGroup, Spinner } from "@blueprintjs/core";
import { ErrorBox } from "components/atoms/error";
import { Column, Row } from "components/atoms/layout";
import { OnlyAdmin } from "components/molecols/OnlyAdmin";
import { GroupComponent } from "components/organismes/group";
import { JsonResponseError, RootState } from "contorller";
import { adminGroupService } from "contorller/admin/groups/controller";
import { adminActions, AdminState } from "contorller/admin/redux";
import { User } from "contorller/auth/types";
import React, { createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import "./scss/admin.scss";

export const AdminPage = () => {
  const state = useSelector<RootState, AdminState>((s) => s.admin);

  const user = useSelector<RootState, User | null>(
    (state) => state.auth.user || null
  );
  const dispatch = useDispatch();

  let ok = true;
  if (!user && user!.admin == false) {
    ok = false;
  }
  useEffect(() => {
    if (ok) {
      adminGroupService()
        .allGroups()
        .then((groups) => {
          dispatch(adminActions.loadGroups(groups));
        })
        .catch((e) => {
          dispatch(adminActions.error(e as JsonResponseError));
        });
    }
  }, [user, ok]);

  if (!ok) return <Redirect to="/" />;

  if (state.sectionLoaded === false) return <Spinner />;

  const ref = createRef<HTMLInputElement>();

  return (
    <OnlyAdmin>
      <Column mainAxisSize="min">
        <Card>
          <h4>Crea consorzio</h4>
          <Row>
            <InputGroup inputRef={ref} placeholder="Nome consorzio" />
            <Button
              onClick={() => {
                const service = adminGroupService();

                service
                  .createGroup({
                    name: ref.current?.value || "",
                    id: "",
                    codes: [],
                    deadline: -1,
                  })
                  .then((group) => dispatch(adminActions.addIntoGroups(group)))
                  .catch((e) =>
                    dispatch(adminActions.error(e as JsonResponseError))
                  );
              }}
            >
              Crea
            </Button>
          </Row>
          <ErrorBox error={state.error} />
        </Card>
        <hr />
        <div className="grid-groups">
          {state.groups.map((e) => {
            return <GroupComponent key={e.id} group={e} />;
          })}
        </div>
      </Column>
    </OnlyAdmin>
  );
};
