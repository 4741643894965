

import { childrenprops } from "components/types"
import { RootState } from "contorller"
import { AuthState } from "contorller/auth/reducer"
import React from "react"
import { useSelector } from "react-redux"



export const SeeLogged = (props: childrenprops) => {

    const auth = useSelector<RootState, AuthState>(state => state.auth)

    return <React.Fragment>
        {auth.isLogged ? props.children : <React.Fragment />}
    </React.Fragment>
}