import { authController } from "contorller/auth/controller";
import { SimulatorRequest } from "contorller/simulations/simulator/handler";
import { newLocalStorage, OfflineStorage } from "contorller/storage/storage";
import { getDays, unixToDate } from "contorller/utils";
import React, { createContext, useContext } from "react";
import { ContinuedLogin, FirstLoginToday, RunSimulation } from "./events";

const isTrue = (e: boolean) => e === true;
function sameDay(d: Date, e: Date): boolean {
  let conditions: boolean[] = [
    d.getDay() == e.getDay(),
    d.getDate() == e.getDate(),
    d.getMonth() == e.getMonth(),
    d.getFullYear() == e.getFullYear(),
  ];
  return conditions.every(isTrue);
}

export interface ProviderAnalytics {
  logEvent(name: string, obj: any): void;
}

interface Analytics {
  /**
   * Provvede a tracciare la "sessione" dell'utente, per sessione si intende:
   *  dal primo login giornaliero, al tracciamento del continuo sostare nella piattaforma
   * all'interno della funzione vengono settati i tempi ultimi di accesso e ogni 15 minuti
   * vengono riviste e segnate nuovamente.
   *
   * la funzione ha all'interno un timer che non viene cancellato e risettato, se richiamata.
   * per cancellare il tracciamento dell'utente usa `stopTraceUser`
   */
  sessionTraceUser: () => void;
  /**
   * Stoppa il tracciamento dell'utente evitanto il risettaggio del time in futuro
   */
  stopTraceUser(): void;
  /**
   * segna l'evento di lancio della simulazione.
   */
  runSimulation(request: SimulatorRequest): void;
}

const keySessionTraceUser = "settionTraceUser";
interface sessionTraceUserData {
  uid: string;
  unix: number;
}

class _analytics implements Analytics {
  private provider: ProviderAnalytics;
  private storage: OfflineStorage;
  private static _instance: Analytics;
  private constructor(provider: ProviderAnalytics, storage: OfflineStorage) {
    this.provider = provider;
    this.storage = storage;
  }

  static instance(
    provider: ProviderAnalytics,
    storage: OfflineStorage
  ): Analytics {
    if (!_analytics._instance) {
      _analytics._instance = new _analytics(provider, storage);
    }
    return _analytics._instance;
  }

  runSimulation(request: SimulatorRequest): void {
    let user = authController().getUser();

    let y = 0;
    let sim = request.simulation || "";
    if (request.start != 0 && request.end != 0 && request.start < request.end) {
      unixToDate(request.start);
      y = getDays(unixToDate(request.start), unixToDate(request.end * 1000));
    }

    this.provider.logEvent(RunSimulation, {
      type: sim,
      uid: user?.uid,
      funds: request.funds.length || 0,
      start: request.start || 0,
      end: request.end || 0,
      years: y,
      method: request.type,
    });
  }
  stopTraceUser(): void {
    if (this.t) clearTimeout(this.t);

    this.blockTrace = true;
  }
  private blockTrace: boolean = false;
  private t?: NodeJS.Timeout;
  sessionTraceUser() {
    if (!this.t && !this.blockTrace) {
      this.t = setInterval(() => {
        let value = this.storage.get(keySessionTraceUser);
        let user = authController().getUser();

        let firstLogin = () => {
          console.log("fistLogin");
          if (user?.uid) {
            this.provider.logEvent(FirstLoginToday, { uid: user?.uid });
            this.storage.set(
              keySessionTraceUser,
              JSON.stringify({
                unix: parseInt((Date.now() / 1000).toString()),
                uid: user?.uid,
              } as sessionTraceUserData)
            );
          }
        };

        let continuedLogin = () => {
          console.log("continued");
          if (user?.uid) {
            this.provider.logEvent(ContinuedLogin, {
              uid: user?.uid,
            });
            this.storage.set(
              keySessionTraceUser,
              JSON.stringify({
                unix: parseInt((Date.now() / 1000).toString()),
                uid: user?.uid,
              } as sessionTraceUserData)
            );
          }
        };

        if (user) {
          if (value === null) {
            // first login today
            firstLogin();
          } else {
            // continue login today
            const data = JSON.parse(value) as sessionTraceUserData;
            // cambinado il numero 15, si cambiano i minuti di sessione
            let less15Minutes = data.unix * 1000 + 1000 * 15 * 60 <= Date.now();
            let less24hour = sameDay(unixToDate(data.unix), new Date());

            if (less15Minutes && !less24hour) {
              continuedLogin();
            }
            if (less24hour) {
              firstLogin();
            }
          }
        }
      }, 5000);
    }
  }
}

export const NewAnalytics = (
  provider: ProviderAnalytics,
  storage: OfflineStorage = newLocalStorage()
): Analytics => {
  return _analytics.instance(provider, storage);
};

const InternalAnalyticsContext =
  createContext<Analytics | undefined>(undefined);

export const useAnalytics = (): Analytics => {
  const ctx = useContext(InternalAnalyticsContext);
  if (!ctx) throw new Error("Non ho trovato il componente AnalyticsContext");
  return ctx;
};

export const AnalyticsContext = (props: {
  children: any;
  value: Analytics;
}) => {
  return (
    <InternalAnalyticsContext.Provider value={props.value}>
      {props.children}
    </InternalAnalyticsContext.Provider>
  );
};
