import { authController } from "contorller/auth/controller";
import React from "react";
import { useHistory } from "react-router";

export const LogoutPage = () => {
  const h = useHistory();
  return (
    <button
      onClick={() => {
        authController().signOut();
        h.push("/");
      }}
    >
      Logout
    </button>
  );
};
