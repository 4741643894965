import { Modal } from "components/atoms/modal";
import { store } from "contorller";
import { AnalyticsContext, NewAnalytics } from "contorller/analytics/analytics";
import { AnalyticsFirebaseProivder } from "contorller/analytics/FirebaseAnalyticsProvider";
import firebase from "firebase";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App, { ReactiveProvider } from "./App";

const root = document.getElementById("root");
if (root == null) {
  throw new Error("Cannot find root element");
}

ReactDOM.unstable_createRoot(root).render(
  <React.StrictMode>
    <Provider store={store}>
      <AnalyticsContext
        value={NewAnalytics(
          new AnalyticsFirebaseProivder(firebase.app().analytics())
        )}
      >
        <Modal>
          <ReactiveProvider>
            <App />
          </ReactiveProvider>
        </Modal>
      </AnalyticsContext>
    </Provider>
  </React.StrictMode>
);
