import { Button, Callout, Classes, IconName, Intent } from "@blueprintjs/core";
import { JsonResponseError } from "contorller";
import React, { useEffect } from "react";
import { useModal } from "../modal";

interface props {
  error?: JsonResponseError;
}

interface AlertProps {
  title: string;
  icon?: IconName;
  description: string;
}

const maxWindowAlert: AlertProps = {
  title: "Durata Simulazione",
  description:
    "La durata della simulazione è troppo ampia rispetto alla serie storica, prova a ridurla.",
  icon: "calendar",
};

const errorCodes: { [key: string]: AlertProps } = {
  "pac-ws-0006": maxWindowAlert,
  "iis-0005": maxWindowAlert,
  "dc-5": maxWindowAlert,
  "picd-5": maxWindowAlert,
};

function ErrorDialog(props: { errorCode: AlertProps; onClose: () => void }) {
  return (
    <>
      <div className={Classes.DIALOG_BODY}>{props.errorCode.description}</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            text="Ok, ho capito"
            icon="tick"
            intent={Intent.PRIMARY}
            onClick={props.onClose}
          />
        </div>
      </div>
    </>
  );
}

export const ErrorBox = (props: props): JSX.Element => {
  const modal = useModal();

  const errorCode = errorCodes[props.error?.code ?? ""];
  useEffect(() => {
    if (errorCode != null) {
      modal.open(<ErrorDialog errorCode={errorCode} onClose={modal.close} />, {
        title: errorCode.title,
        icon: errorCode.icon,
      });
    }
  }, [modal, errorCode]);

  if (!props.error) return <React.Fragment />;
  if (errorCode != null) {
    return <React.Fragment />;
  }

  return (
    <Callout
      className="error-reporter"
      intent={Intent.DANGER}
      title={props.error.error}
      icon="warning-sign"
    >
      <b>code: #{props.error.code}</b>
    </Callout>
  );
};
