import { config } from "config";
import { JsonResponseError } from "contorller";
import { authController } from "contorller/auth/controller";

export interface Group {
  id: string;
  name: string;
  deadline: number;
  codes: string[];
}

/**
 * Un interfaccia che descrive la comunicazione con il servizio gruppi (consorzi)
 * del servizio auth.
 */
interface ControllerGroupService {
  createGroup(group: Group): Promise<Group>;
  deleteGroup(id: string): Promise<Group>;
  editGroup(id: string, group: Group): Promise<Group>;

  allGroups(): Promise<Group[]>;

  addUserCode(id: string, userCode: string): Promise<Group>;
  removeUserCode(id: string, userCode: string): Promise<Group>;
}

export const adminGroupService = (): ControllerGroupService =>
  controller.instance();

class controller implements ControllerGroupService {
  private constructor() {}
  async createGroup(group: Group): Promise<Group> {
    let auther = authController().getAuther();
    const response = await fetch(config().URL + "/users/group", {
      headers: await auther.getHeaderToken(),
      body: JSON.stringify(group),
      method: "POST",
    });
    if (response.status === 200) {
      const data: Group = await response.json();
      return data;
    } else {
      const data: JsonResponseError = await response.json();
      throw data;
    }
  }
  async deleteGroup(id: string): Promise<Group> {
    throw "Non implementato.";
    // let auther = authController().getAuther();
    // const response = await fetch(config().URL+"/users/group", {
    //   headers: await auther.getHeaderToken(),
    //   body: JSON.stringify(group),
    //   method:"POST"
    // });
    // if (response.status === 200) {
    //   const data: Group = await response.json();
    //   return data;
    // } else {
    //   const data: JsonResponseError = await response.json();
    //   throw data;
    // }
  }
  async editGroup(id: string, group: Group): Promise<Group> {
    let auther = authController().getAuther();
    const response = await fetch(config().URL + "/users/group/" + id, {
      headers: await auther.getHeaderToken(),
      body: JSON.stringify(group),
      method: "POST",
    });
    if (response.status === 200) {
      const data: Group = await response.json();
      return data;
    } else {
      const data: JsonResponseError = await response.json();
      throw data;
    }
  }
  async allGroups(): Promise<Group[]> {
    let auther = authController().getAuther();
    const response = await fetch(config().URL + "/users/group", {
      headers: await auther.getHeaderToken(),
      method: "GET",
    });
    if (response.status === 200) {
      const data: Group[] = await response.json();

      return data;
    } else {
      const data: JsonResponseError = await response.json();
      throw data;
    }
  }
  async addUserCode(id: string, userCode: string): Promise<Group> {
    let auther = authController().getAuther();
    const response = await fetch(
      config().URL + `/users/group/${id}/codes?code=${userCode}`,
      {
        headers: await auther.getHeaderToken(),
        method: "PUT",
      }
    );
    if (response.status === 200) {
      const data: Group = await response.json();
      return data;
    } else {
      const data: JsonResponseError = await response.json();
      throw data;
    }
  }
  async removeUserCode(id: string, userCode: string): Promise<Group> {
    let auther = authController().getAuther();
    const response = await fetch(
      config().URL + `/users/group/${id}/codes?code=${userCode}`,
      {
        headers: await auther.getHeaderToken(),
        method: "DELETE",
      }
    );
    if (response.status === 200) {
      const data: Group = await response.json();
      return data;
    } else {
      const data: JsonResponseError = await response.json();
      throw data;
    }
  }

  private static _instance: ControllerGroupService;
  static instance(): ControllerGroupService {
    if (!controller._instance) {
      controller._instance = new controller();
    }
    return controller._instance;
  }

  // groupController(id: string): ControllerGroupUsers {
  //   return new controllerGroup(id, this);
  // }
}

// interface ControllerGroupUsers {
//   deleteGroup(): Promise<void>;
//   editGroup(name: string): Promise<void>;
//   addCode(code: string): Promise<Group>;
//   removeCode(code: string): Promise<Group>;
// }

// class controllerGroup implements ControllerGroupUsers {
//   private id: string;
//   private controller: ControllerGroupService;
//   constructor(id: string, controller: ControllerGroupService) {
//     this.id = id;
//     this.controller = controller;
//   }

//   deleteGroup(): Promise<void> {
//     throw new Error("Method not implemented.");
//   }
//   async editGroup(name: string): Promise<void> {
//     await this.controller.editGroup(this.id, { name: name } as Group);
//   }
//   async addCode(code: string): Promise<Group> {
//     return await this.controller.addUserCode(this.id, code);
//   }
//   async removeCode(code: string): Promise<Group> {
//     return await this.controller.removeUserCode(this.id, code);
//   }
// }
