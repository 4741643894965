import { Spinner } from '@blueprintjs/core';
import { propsWithParams } from 'components/types';
import { RootState } from 'contorller';
import { authController } from 'contorller/auth/controller';
import { AuthState } from 'contorller/auth/reducer';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

export default function LegacyLoginPage(p: propsWithParams) {

  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  useEffect(()=> {
    authController().signInWithCode(p.match.params.code);
  }, []);

  if (auth.isLogged) {
    return <Redirect to="/" />;
  }
 
  return <Spinner />;
}