import { Card } from "@blueprintjs/core";
import { ErrorBox } from "components/atoms/error";
import { Column, Row } from "components/atoms/layout";
import { LoginForm } from "components/organismes/login";
import { RootState } from "contorller";
import { authController } from "contorller/auth/controller";
import { AuthState } from "contorller/auth/reducer";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import "./scss/login.scss";

const controller = authController();

export const LoginPage = (): JSX.Element => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  if (auth.isLogged) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <br />
      <Row mainAxisAlignment="space-around">
        <Card>
          <Column mainAxisSize="min" mainAxisAlignment="center">
            <h1>Login</h1>

            <LoginForm
              onLogin={async (email, password) => {
                await controller.signIn(email, password);
              }}
            />
            <ErrorBox error={auth.error} />
            {/* <Link to="/signup">Registra un nuovo account</Link> */}
          </Column>
        </Card>
      </Row>
    </div>
  );
};
