import { PICResponse } from "../types";
import { calcPic, myFormatDate } from "./fx";


export interface optionsRollingPic {
    range: {
      day: number;
      month: number;
      year: number;
    };
    step: {
      day: number;
      month: number;
      year: number;
    };
  }
  
export interface PICRollingResponse {
    absolute: number;
    annual: number;
    start: Date;
    end: Date;
  }
  
  export function getRollingPic(
    simulation: PICResponse,
    options: optionsRollingPic
  ): PICRollingResponse[] {
    const response: PICRollingResponse[] = [];
    let indexStart: number | undefined = undefined;
    const daysFormatted = simulation.snaps.map((e, index) => {
      let d = new Date(e.unix * 1000);
      if (indexStart === undefined) {
        if (d.getDate() === 1) {
          indexStart = index;
        }
      }
      return myFormatDate(d);
    });
  
    let day = new Date(simulation.snaps[indexStart || 0].unix * 1000);
    while (true) {
      let today = new Date(day);
      let next = new Date(today);
  
      next.setDate(next.getDate() + options.range.day);
      next.setMonth(next.getMonth() + options.range.month);
      next.setFullYear(next.getFullYear() + options.range.year);
  
      if (
        daysFormatted.indexOf(myFormatDate(next)) === -1 ||
        daysFormatted.indexOf(myFormatDate(day)) === -1
      ) {
        break;
      }
  
      // let simStart = simulation.snaps[daysFormatted.indexOf(myFormatDate(day))];
      // let simEnd = simulation.snaps[daysFormatted.indexOf(myFormatDate(next))];
      
      // let sim = {
      //   absolute: absoluteEarge(Sum(simEnd.funds), Sum(simStart.funds)),
      //   annual:  annualEarge(Sum(simEnd.funds), Sum(simStart.funds), getDays(new Date(simStart.unix*1000), new Date(simEnd.unix*1000),)),
      // }
  
  
      //calcolo pic per pic
      let simStart = daysFormatted.indexOf(myFormatDate(today));
      let simEnd = daysFormatted.indexOf(myFormatDate(next));
      let history = simulation.snaps.slice(simStart, simEnd+1)
      
      const sim = calcPic( simulation.request, history, simStart%simulation.request.rebalance)
      response.push({
        start: new Date(today),
        end: new Date(next),
        absolute: sim.absolute,
        annual: sim.annual,
      });
  
      day.setDate(day.getDate() + options.step.day);
      day.setMonth(day.getMonth() + options.step.month);
      day.setFullYear(day.getFullYear() + options.step.year);
    }
    return response;
  }