import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonResponseError } from "contorller";
import { EarnTable } from "../types";

export interface SpotOperation {
  id: string;
  unix: number;
  money: number;
}

export interface PACSpotState {
  showWindowSpot: boolean;
  operations: SpotOperation[];
  value: number;
}

export interface PACState {
  wsReady: boolean;
  isRunning: boolean;
  error?: JsonResponseError;

  index: number;
  total: number;
  loading: boolean;
  profiling: {
    start: number;
    end: number;
  };
  earnTable: EarnTable;
  loadingEarnTable: boolean;
  spots: PACSpotState;
}

const initialState: PACState = {
  wsReady: true,
  isRunning: false,
  index: 0,
  total: 0,
  loading: false,
  earnTable: {
    absolute: { min: 0, max: 0, avg: 0 },
    annual: { min: 0, max: 0, avg: 0 },
  },
  profiling: { start: 0, end: 0 },
  loadingEarnTable: false,
  spots: {
    showWindowSpot: false,
    operations: [],
    value: 0,
  },
};

const pacStore = createSlice({
  name: "pac",
  initialState,
  reducers: {
    startEarn: (state) => {
      state.loadingEarnTable = true;
    },
    endEarn: (state, action: PayloadAction<{ earnTalbe: EarnTable }>) => {
      state.loadingEarnTable = false;
      state.earnTable = action.payload.earnTalbe;
    },

    start: (state) => {
      state.isRunning = true;
      state.loading = true; //TODO: da togliere
      state.error = undefined;
    },
    end: (state) => {
      state.isRunning = false;
      state.loading = false; //TODO: da togliere
    },

    startSimulation: (state, action: PayloadAction<{ total?: number }>) => {
      state.index = 0;
      state.total = action.payload.total ?? 0;
      state.loading = true;
      state.profiling.start = Date.now();
      state.error = undefined;
      state.loadingEarnTable = true;
    },

    updateSimulation: (state, action: PayloadAction<{ index: number }>) => {
      state.index = action.payload.index;
    },

    selectSimulation: (state, action: PayloadAction<{ index: number }>) => {
      state.index = action.payload.index;
    },

    endSimulation: (state, action: PayloadAction<{ table: EarnTable }>) => {
      state.loading = false;
      state.profiling.end = Date.now();
      state.wsReady = true;
      console.log(
        `Simulazione PAC  ${state.profiling.end - state.profiling.start}ms `
      );
      state.earnTable = action.payload.table;
      state.loadingEarnTable = false;
    },
    websocketReady: (state) => {
      state.wsReady = true;
      state.error = undefined;
    },
    websocketNotReady: (state) => {
      state.wsReady = false;
    },
    error: (state, action: PayloadAction<{ error: JsonResponseError }>) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    ok: (state) => {
      state.error = undefined;
    },
    addSpotOperation: (
      state,
      action: PayloadAction<{ operation: SpotOperation }>
    ) => {
      console.log(
        `[PAC][Add-SPOT] ${action.payload.operation.money} ${new Date(
          action.payload.operation.unix * 1000
        )} `
      );
      if (action.payload.operation.money === 0) return;
      if (
        state.spots.operations
          .map((e) => e.unix)
          .indexOf(action.payload.operation.unix) === -1
      ) {
        state.spots.operations.push({
          ...action.payload.operation,
          id: `#spot-${state.spots.operations.length}`,
        });
      } else {
        state.spots.operations.forEach((e) => {
          e.money += action.payload.operation.money;
        });
      }

      state.spots.operations.sort((a, b) => a.unix - b.unix);
    },
    removeSpotOperations: (state, action: PayloadAction<{ id: string }>) => {
      state.spots.operations = state.spots.operations.filter(
        (e) => e.id !== action.payload.id
      );
      state.spots.operations.sort((a, b) => a.unix - b.unix);
    },
    showSpot: (state) => {
      state.spots.operations = [];
      state.spots.showWindowSpot = true;
    },
    hideSpot: (state) => {
      state.spots.operations = [];
      state.spots.showWindowSpot = false;
    },
    reset: (state) => {
      state = initialState;
    },
    changeSpotValue: (state, action: PayloadAction<{ value: number }>) => {
      state.spots.value = action.payload.value;
    },
  },
});

export const PACActions = pacStore.actions;
export const PACReducer = pacStore.reducer;

/*

        case "portfolio/pac/goals":
            if (!state.pac) throw new Error("non vedo il pac response")
            if (!action.pacGoals) throw new Error("non vedo i goals")
            state.pac.responses.forEach((e, i) => {
                if (action.pacGoals) {
                    e.annual = action.pacGoals.responses[i].value
                    const val = e.response.snaps[e.response.snaps.length - 1].funds
                    const money = e.params.investments.map<number>(k => k.money || 0)
                    e.absolute = 1 - ((e.params.initialInvestment.money + Sum(money)) / Sum(val))
                }
            })
            return { ...state, pacGoals: action.pacGoals }
*/
