import { Navbar } from "@blueprintjs/core";
import { Logo } from "components/atoms/logo";
import React from "react";

export default function BancoNavbar() {
  return (
    <Navbar>
      <Navbar.Group>
        <Navbar.Heading>
          <Logo
            style={{
              backgroundColor: "#0E0B20",
            }}
            banco={true}
            color="light"
            size="medium"
          />
        </Navbar.Heading>
      </Navbar.Group>
    </Navbar>
  );
}
