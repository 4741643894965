import { getColorPaletteAtIndex } from "config";
import { Sum } from "contorller";
import { unixToDate } from "contorller/utils";
import { PICResponse } from "../types";

export const PIC_LINE_WICTH_PORTFOLIO = 2;
export const PIC_LINE_WIDTH_FUND = 1;

export interface graphOptions {
  hidenIdsFund: string[];
}

export interface picSnapGraph {
  fundNames: string[];
  values: number[];
  value: number;
  quotes: number[];
  day: Date;
  rebalance: boolean;
}

interface graphLeggend {
  name: string;
  id: string;
  type: "fund" | "pic";
  color: string;
  active: boolean;
}
export interface picResponseGraph {
  x: Date[];
  y: number[];
  text: string;
  id: string;
  lineWidth: number;
  leggend: graphLeggend[];
}

export interface OptionsPicGraph {
  showFunds: boolean;
  showMeanPerfomance: boolean;
  debug?: boolean;
}

export function picGraph(
  picresponse: PICResponse[],
  options: OptionsPicGraph
): picResponseGraph[] {
  if (options.debug) {
    console.log(
      `[picgraph] picaresponse.length: ${picresponse.length}`,
      options
    );
  }

  if (picresponse.length === 1) {
    const leggend: graphLeggend[] = [];
    const result: picResponseGraph[] = [];
    leggend.push({
      name: picresponse[0].name,
      id: picresponse[0].id,
      type: "pic",
      color: getColorPaletteAtIndex(0),
      active: true,
      // active: options.hiddenPicResponseId.indexOf(picresponse[0].id) === -1,
    });
    if (options.showFunds) {
      picresponse[0].request.funds.forEach((e, i) =>
        leggend.push({
          name: e.name,
          id: e.id,
          type: "fund",
          color: getColorPaletteAtIndex(i + 1),
          active: true,

          // active: options.hiddenFundIds.indexOf(e.id) === -1,
        })
      );
    }

    // if (options.hiddenPicResponseId.indexOf(picresponse[0].id) === -1) {
    result.push({
      x: picresponse[0].snaps.map((e) => unixToDate(e.unix)),
      y: picresponse[0].snaps.map((e) => Sum(e.funds)),
      text: picresponse[0].name,
      id: picresponse[0].id,
      leggend: leggend,
      lineWidth:
        options.showFunds === true
          ? PIC_LINE_WICTH_PORTFOLIO
          : PIC_LINE_WIDTH_FUND,
    });
    // }
    if (options.showFunds) {
      if (options.debug) {
        console.log(
          `[picgraph] picaresponse.length: ${picresponse.length} funds:${picresponse[0].request.funds.length}`,
          options
        );
      }
      picresponse[0].request.funds.forEach((fund, index) => {
        result.push({
          x: picresponse[0].snaps.map((e) => unixToDate(e.unix)),
          // questa linea di codice rimodula i fondi in base al peso espresso nel portafoglio
          // se ad esempio nel giorno del rebalance: il controvalore fosse 250 ma il peso è 0.25 il valore che ci aspettiamo
          // è 1000 in qunato Alberto mi ha chiesto di graficare il confronto dei fondi con il portafoglio
          // rimodulando i risultati
          y: picresponse[0].snaps.map(
            (e) => e.funds[index] / picresponse[0].request.funds[index].weight
          ),
          text: fund.name,
          id: fund.id,
          leggend: leggend,
          lineWidth: PIC_LINE_WIDTH_FUND,
        });
      });
    }

    if (options.debug) {
      console.log(
        `[picgraph] picaresponse.length: ${picresponse.length} results:${result.length}`
      );
    }

    return result;
  } else {
    const result: picResponseGraph[] = [];
    const funds: graphLeggend[] = picresponse.map<graphLeggend>((e, i) => {
      return {
        name: e.name,
        id: e.id,
        type: "pic",
        color: getColorPaletteAtIndex(i),
        active: true,
      };
    });
    picresponse.forEach((response, index) => {
      result.push({
        text: response.name,
        x: response.snaps.map((e) => unixToDate(e.unix)),
        y: response.snaps.map((e) => Sum(e.funds)),
        id: response.id,
        leggend: funds,
        lineWidth: PIC_LINE_WIDTH_FUND,
      });
    });
    return result;
  }
}
