import { Card } from "@blueprintjs/core";
import { ErrorBox } from "components/atoms/error";
import { Column, Row } from "components/atoms/layout";
import { SignupForm } from "components/organismes/signup";
import { RootState } from "contorller";
import { authController } from "contorller/auth/controller";
import { AuthState } from "contorller/auth/reducer";
import React from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

export const SignUpPage = (): JSX.Element => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  if (auth.user) {
    return <Redirect to="/" />;
  }
  return (
    <div>
      <Row mainAxisAlignment="space-around">
        <Column mainAxisAlignment="center">
          <Card>
            <h1>Registrati</h1>

            <SignupForm
              onSignUp={async (data) => {
                const controller = authController();
                await controller.signUp(data);
              }}
            />
            <ErrorBox error={auth.error} />
            <Link to="/signin">hai già un account?</Link>
          </Card>
        </Column>
      </Row>
    </div>
  );
};
