import firebase from "firebase/app";
import "firebase/auth";

const firebaseStage = {
  apiKey: "AIzaSyAmMa_DmjmZsUO6b3YNMJEV2BB_h4jR8dc",
  authDomain: "empirich-dev.firebaseapp.com",
  projectId: "empirich-dev",
  storageBucket: "empirich-dev.appspot.com",
  messagingSenderId: "927964726320",
  appId: "1:927964726320:web:61c59aa6be64eae9d519f5",
  measurementId: "G-T7E5Z6NK9P",
};

const firebaseProd = {
  apiKey: "AIzaSyDwiuEKc5RobhqQ-GSSqHl38xZrZSamJr0",
  authDomain: "empirich-2.firebaseapp.com",
  projectId: "empirich-2",
  storageBucket: "empirich-2.appspot.com",
  messagingSenderId: "351351687034",
  appId: "1:351351687034:web:9ecb28fd2f8a1d664e78c3",
  measurementId: "G-YXNZ5ETVPX",
};

const firebaseMes = {
  apiKey: "AIzaSyCbslV4n6Z1n1NEqscyXJtVt-Qd0dFvV1w",
  authDomain: "encoded-source-277806.firebaseapp.com",
  databaseURL: "https://encoded-source-277806.firebaseio.com",
  projectId: "encoded-source-277806",
  storageBucket: "encoded-source-277806.appspot.com",
  messagingSenderId: "387332174380",
  appId: "1:387332174380:web:a3d7d900f5403f6d709789",
  measurementId: "G-461SD3R6YJ",
};

export const firebaseApp = () => {
  switch (getStatusStage()) {
    case "prod":
      console.log("[firebase] prod init");
      return firebase.initializeApp(firebaseProd);
    case "test":
      return firebase.initializeApp(firebaseStage);
    case "stage":
      console.log("[firebase] stage init");
      return firebase.initializeApp(firebaseStage);
    case "mes":
      console.log("[firebase] mes init");
      return firebase.initializeApp(firebaseProd);
    case "localhost":
      return firebase.initializeApp(firebaseStage);
  }
};

export const MAINANTENCE_MODE = false;

export const STAGE_ENDPOINT = "https://stage.api.empirich.com";
export const PROD_ENDPOINT = "https://api.empirich.com";
export const STAGE_FRONTEND = "https://stage.empirich.com";
export const PROD_FRONTEND = "https://v2.empirich.com";

interface configApp {
  URL: string;
  FRONTEND: string;
  MAINANTENCE_MODE: boolean;
  WS: string;
  STRIPE_TOKEN: string;
  ES: string;
  STAGE: STAGE;
}

function getURL(stage: STAGE): string {
  switch (stage) {
    case "prod":
      return "https://api.empirich.com";
    case "mes":
      return "https://api.empirich.com";
    case "stage":
      return "https://stage.api.empirich.com";
    case "localhost":
      return "http://localhost:8000";
    default:
      throw new Error("Non hai settato nulla per questo stage");
  }
}
function getFrontend(stage: STAGE): string {
  switch (stage) {
    case "prod":
      return PROD_FRONTEND;
    case "mes":
      return PROD_FRONTEND;
    case "stage":
      return STAGE_FRONTEND;
    case "localhost":
      return "http://localhost:3000";
    default:
      throw new Error("Non hai settato nulla per questo stage");
  }
}

type STAGE = "prod" | "localhost" | "stage" | "test" | "mes";

function getStripeToken(): string {
  switch (process.env.REACT_APP_NOT_SECRET_CODE) {
    case "prod":
      // TODO: striep token di test, throw new Error("non sono pronto per la produzione");
      return "pk_test_51HWluoHpI0eEacEFhpmgDIXsqc3Tu7AKKpEfBfyqAnMnxmLuHwLQxQmaBEFbplw8DhQHeDIfjvhcjzfOtCAkTtH100chm1iEGs";
    case "stage":
      // todo: questo è un test token da cambiare.
      return "pk_test_51HWluoHpI0eEacEFhpmgDIXsqc3Tu7AKKpEfBfyqAnMnxmLuHwLQxQmaBEFbplw8DhQHeDIfjvhcjzfOtCAkTtH100chm1iEGs";
    default:
      return "pk_test_51HWluoHpI0eEacEFhpmgDIXsqc3Tu7AKKpEfBfyqAnMnxmLuHwLQxQmaBEFbplw8DhQHeDIfjvhcjzfOtCAkTtH100chm1iEGs";
  }
}

function getES(stage: STAGE): string {
  switch (stage) {
    case "prod":
      return "https://api.empirich.com/elastic/";
    case "stage":
      // todo: questo è un test token da cambiare.
      return "https://stage.api.empirich.com/elastic/";
    case "localhost":
      return "http://localhost:19200";
    case "mes":
      return "https://api.empirich.com/elastic/";
    default:
      throw new Error("Non hai settato nulla per questo stage");
  }
}
export const getStatusStage = (): STAGE =>
  process.env.REACT_APP_NOT_SECRET_CODE as STAGE;
export const config = (): configApp => {
  if (process.env.NODE_ENV === "test") {
    return {
      URL: "/",
      FRONTEND: "/",
      MAINANTENCE_MODE,
      WS: "ws://localhost:12345",
      STRIPE_TOKEN: "",
      ES: "/",
      STAGE: "test",
    };
  }

  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "production"
  )
    return {
      URL: getURL(getStatusStage()),
      FRONTEND: getFrontend(getStatusStage()),
      WS: getURL(getStatusStage())
        .replace("https://", "wss://")
        .replace("http://", "ws://"),
      STRIPE_TOKEN: getStripeToken(),
      MAINANTENCE_MODE,
      ES: getES(getStatusStage()),
      STAGE: getStatusStage(),
    };

  throw new Error("env error");
};

export function getColorPalette() {
  return [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    // "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
  ];
}

export function getColorPaletteAtIndex(index: number): string {
  const palette = getColorPalette();
  return palette[index % palette.length];
}
