import { Sum } from "contorller";
import { Portfolio } from "./types";

export function fundName(id: string, portfolio?: Portfolio): string {
  if (portfolio)
    for (let i of portfolio.funds) {
      if (i.id === id) {
        return i.fund.name;
      }
    }

  throw new Error("non trovo questo fondo");
}

export interface PercentageError {
  error: boolean;
  percentageError: number;
  percentage: number;
}

export function checkPercentagePortfolio(
  portfolio: Portfolio
): PercentageError {
  if (portfolio.type === "percentage" && portfolio.funds.length > 0) {
    const percentages = portfolio.funds.map((e) => {
      //console.log("singola percentuale",  parseFloat(e.percentage.toFixed(3)))
      return parseFloat(e.percentage.toFixed(5));
    });
    const p = Sum(percentages);
    // console.log("Percentuale",p)
    return {
      error: p.toFixed(3) !== (1).toFixed(3),
      percentage: p,
      percentageError: 1 - p,
    };
  } else {
    return {
      error: false,
      percentage: 1,
      percentageError: 0,
    };
  }
}

export function percentageFundRebalance(portfolio: Portfolio): number[] {
  if (portfolio.type === "percentage") {
    let percentages = portfolio.funds.map((e) => e.percentage);

    //if (Sum(percentages) === 1) return percentages;

    const fixedPercentage = parseFloat(
      (1.0 / portfolio.funds.length).toFixed(4)
    );
    const totalPercentage = fixedPercentage * portfolio.funds.length;
    const evenPercentage =
      totalPercentage !== 1
        ? 1 - fixedPercentage * (portfolio.funds.length - 1)
        : fixedPercentage;

    return portfolio.funds.map((_, index) =>
      index === 0 ? evenPercentage : fixedPercentage
    );
  } else {
    return portfolio.funds.map((e) => e.percentage);
  }
}

// export function annualEargePAC(M:number, invest:number[],):number{
//     let days:number = invest.length
//     return 0
// }
